.product-line {
    display: flex;

}

.product-image {
    width: 50%;
    padding: 30px;
}

.product-desc {
    width: 50%;
    padding: 30px;
}

.product-desc-full {
    width: 100%;
    padding: 30px;
}

.product-detailed {
    width: 100%;

}

.banners img {
    margin-top: 20px;
    width: 100%;
}


.news img {
    float: left;
    width: 100px;
    height: 80px;
    padding: 0 5px 5px 5px;
    margin-right: 10px;
}

news .news_detailed img {
    max-width: 300px;
    max-height: 240px;
}


.news__text {
    color: #00439F;
}

.news__text span {
    font-weight: bold;
    color: gray;
    text-decoration: none;
}

.news__date {
    margin-right: 5px;
}

.row {
    display: flex;
    flex-direction: column;
}

.row__link {
    box-shadow: rgb(240 240 245) 0px -1px 0px inset;
    flex-grow: 1;
    cursor: default;
}

a .row__link, a.row__link {
    cursor: pointer;
}

.row__link:hover {
    box-shadow: rgb(204 204 204) 0px -1px 0px inset;
}

.contact-form {
    /*text-align: center;*/
    margin: 0 auto;
}

.contact-form input, .contact-form textarea {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.contact-form textarea {
    height: auto;
    margin-bottom: 20px;
}

.contact-form label {
    font-size: 15px;
}

.Grid1 {
    margin-top: 30px;
}

.apartment-table {
    clear: both;
    width: 50%;
    border-collapse: collapse;
    margin: auto;
}

.apartment-table td {
    border: solid 1px;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
    font-size: 11pt;
}

.color0 {
    color: rgb(0, 0, 255);
    font-weight: bold;
}

.color1 {
    color: rgb(255, 207, 210);
}

.color1Brighter {
    color: rgb(255, 0, 0);
}

.flag_color {
    padding: 0 10px 10px;
    float: left;
    font-size: 13px;
}

.flag_color_wrapper {
    display: flex;
    justify-content: center;
    margin-top: -30px;
}

.apartment-table .firstSell {
    padding: 0 40px;
}

.firstSell {
    padding: 0 40px;
    background: linear-gradient(to bottom, #fff -15%, #87ade7 100%);
    text-shadow: 0 1px 0 #fff;
    font-weight: bold;
}

td .button.button--secondary {
    cursor: pointer;
}

.text_planirovka {
    text-align: center;
    margin-bottom: 3%;
}


.planirovka {
    display: flex;

}

.column {
    text-align: center;

}

.column img {
    max-height: 560px;
    max-width: 95%;
    margin-top: 2%;

}

.column.column2 {
    margin-left: 2%;
}

@media screen and (min-width: 10px) and (max-width: 1199px) {
    .apartment-table td {
        display: block;
    }
}

.row-info {
    text-align: left;
    font-weight: 700;
}

.row-info2 {
    color: rgb(53, 142, 233);
}

.row-info1 {
    color: rgb(52, 71, 103);
}

.row-info-wrap {
    margin-top: 1px;
}

.wordwrap {
    white-space: nowrap;
}

.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    pointer-events: all;
    opacity: 1;
}

.modal__content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    /*width: 80vw;*/
    transform: scale(0.5);
    transition: 0.4s all;
    margin: 5%;
}

.modal__content.active{
    transform: scale(1);
}

.list-offices {
    width: 100%;
}

.list-offices__col1 {
    width: 30%;
}

.list-offices__col2 {
    width: 20%;
}
.list-offices__col3 {
    width: 20%;
}
.list-offices__col4 {
    width: 30%;
}

.list-offices__row1 {
    color: orange;
}

.list-offices__row1 td {
    padding-bottom: 10px;
}

